import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Table,
    Button,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { apiService } from "../../service/api.service";
import swal from "sweetalert";
import { baseUrl } from "../../config/config";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

let emptyData = {
    title: "",
    description: "",
    image: null,
    options: ["", ""]
}

export const MultiChoicePredictionTemplates = () => {
    const [soloPredictionsList, setSoloPredictionsList] = useState([])
    const [loader, setLoader] = useState(false)
    const [totalItems, setTotalItems] = useState();
    const [page, setPage] = useState(0);
    const [modalVisible, setModalVisible] = useState(false)
    const [updateSoloData, setUpdateSoloData] = useState(emptyData)
    const [updateSoloError, setUpdateSoloError] = useState(emptyData)
    const [soloTypeFiltersel, setSoloTypeFiltersel] = useState("");
    // soloTypeFilteroptions
    const history = useNavigate();
    const [soloSearch, setSoloSearch] = useState("");


    useEffect(() => {
        onGetSolo(0, "", soloSearch)
    }, [])

    const onGetSolo = async (page, soloType, search) => {
        setLoader(true);
        try {

            const response = await apiService.getMultiPrediction(page + 1, soloType ? soloType : "", search);
            if (response.status == 200) {
                setSoloPredictionsList(response.data.data)
                setTotalItems(response?.data?.pagination?.totalItems);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }

    function handlePageChange(pageNumber) {
        if (page != pageNumber - 1) {
            setPage(pageNumber - 1);
            onGetSolo(pageNumber - 1, soloTypeFiltersel, soloSearch)
        }
    }


    const handleError = (type, Message) => {
        setUpdateSoloError(prevQuestionAnswer => {
            return {
                ...prevQuestionAnswer,
                [type]: Message
            };
        });
    }


    const onSelectImage = (e) => {
        const file = e.target.files[0];
        setUpdateSoloData((prevState) => ({ ...prevState, image: file }));
        handleError("image", "")
    }

    function onChangeText(e) {
        let { name, value } = e.target;
        setUpdateSoloData(prevQuestionAnswer => ({
            ...prevQuestionAnswer,
            [name]: value.trimStart()
        }));
        handleError([name], "")

    }

    const addOption = () => {
        setUpdateSoloData((prevState) => ({
            ...prevState,
            options: [...prevState.options, ""],
        }));
        setUpdateSoloError((prevState) => ({
            ...prevState,
            options: [...prevState.options, ""],
        }));
    };

    const updateOption = (index, value) => {
        const updatedOptions = [...updateSoloData.options];
        updatedOptions[index] = value;

        setUpdateSoloData((prevState) => ({
            ...prevState,
            options: updatedOptions,
        }));

        // Clear error if any
        const updatedErrors = [...updateSoloError.options];
        updatedErrors[index] = "";

        setUpdateSoloError((prevState) => ({
            ...prevState,
            options: updatedErrors,
        }));
    };

    const removeOption = (index) => {
        const updatedOptions = updateSoloData.options.filter((_, idx) => idx !== index);
        setUpdateSoloData((prevState) => ({
            ...prevState,
            options: updatedOptions,
        }));

        const updatedErrors = updateSoloError.options.filter((_, idx) => idx !== index);
        setUpdateSoloError((prevState) => ({
            ...prevState,
            options: updatedErrors,
        }));
    };


    const onCloseModal = () => {
        setModalVisible(false)
        setUpdateSoloData(emptyData)
        setUpdateSoloError(emptyData)
    }

    const onSave = () => {
        let valid = true;
        // if (!updateSoloData.image) {
        //     handleError("image", "Image is required.")
        //     valid = false;
        // }
        if (!updateSoloData.title.trim()) {
            handleError("title", "Title is required.")
            valid = false;
        }
        if (!updateSoloData.description.trim()) {
            handleError("description", "Description is required.")
            valid = false;
        }
        updateSoloData.options.forEach((option, index) => {
            if (!option.trim()) {
                setUpdateSoloError(prevQuestionAnswer => {
                    const updatedAnswerOption = [...prevQuestionAnswer.options];
                    updatedAnswerOption[index] = 'Option is required.';
                    return {
                        ...prevQuestionAnswer,
                        options: updatedAnswerOption
                    };
                });
                valid = false;
            } else {
                console.log('Please select', updateSoloData.options)
                let isUnique = updateSoloData.options.filter(data => data.toLowerCase() === option.toLowerCase()).length > 1;
                if (isUnique) {
                    setUpdateSoloError(prevQuestionAnswer => {
                        const updatedAnswerOption = [...prevQuestionAnswer.options];
                        updatedAnswerOption[index] = 'Options must be unique.';
                        return {
                            ...prevQuestionAnswer,
                            options: updatedAnswerOption
                        };
                    });
                    valid = false;
                }
            }
        });

        if (!valid) {
            return;
        } else {
            let data = new FormData();
            data.append("title", updateSoloData.title);
            data.append("description", updateSoloData.description);
            data.append("options", JSON.stringify(updateSoloData.options));
            data.append("timesAdded", 2);
            if (updateSoloData.image) {
                data.append("image", updateSoloData.image);
            }
            if (updateSoloData?._id) {
                onUpdateSolo(data, updateSoloData?._id)
            } else {
                onAddingSolo(data)
            }
        }
    };


    const onAddingSolo = async (data) => {
        setLoader(true);
        try {
            const response = await apiService.addSoloPredictions(data);
            if (response.status == 201) {
                onCloseModal();
                setPage(0)
                onGetSolo(0, soloTypeFiltersel, soloSearch);
                swal("Success", response?.data?.message, "success").then(() => { });
            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }


    const onUpdateSolo = async (data, id) => {
        setLoader(true);
        try {
            const response = await apiService.updateMultiPredictionTemplate(data, id);
            if (response.status == 200) {
                onCloseModal();
                setPage(0)
                onGetSolo(0, soloTypeFiltersel, soloSearch);
                swal("Success", "Multi Choice Prediction templates details updated successfully.", "success").then(() => { });

            }
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            } else {
            }
        }
    }

    const onChangeStatus = async (id, status) => {
        let data = ({
            isDisabled: status
        })
        setLoader(true)
        try {
            const response = await apiService.changeSoloStatus(data, id);
            if (response.status == 200) {
                setPage(0)
                onGetSolo(0, soloTypeFiltersel, soloSearch);
            }
            setLoader(false)
        } catch (error) {
            setLoader(false);
            if (error?.response?.status == 401) {
                swal({ text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                });
            }
        }
    }

    const soloTypeFilteroptions = [
        { value: "", label: "All" },
        { value: "pearls", label: "Pearls" },
        { value: "sapphires", label: "Sapphires" },
        { value: "rubies", label: "Rubies" },
        { value: "emeralds", label: "Emeralds" },
        { value: "diamonds", label: "Diamonds" },
    ];


    function handleSoloTypeFilterChange(optionSelected) {
        setPage(0)
        setSoloTypeFiltersel(optionSelected);
        onGetSolo(0, optionSelected, soloSearch);

    }

    function launchPrediction(templateId) {
        try {
            swal({
                text: `Do you want to launch prediction?`,
                icon: "info",
                buttons: ["No", "Yes"]
            }).then(async (res) => {
                if (res) {
                    setLoader(true);
                    const response = await apiService.multichoicePredictionLaunch(templateId);
                    if (response.status == 200) {
                        swal({
                            text: `${response.data.message}`,
                            icon: "success",
                        });
                    }
                    setLoader(false);
                }
            })


        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }

    }


    function handleSearchChange(search) {
        setPage(0)
        setSoloSearch(search);
        onGetSolo(0, soloTypeFiltersel, search);
    }

    return (
        <>
            <div className="dashboard-main-area">
                {loader ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={12} lg={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex ">
                                        <h2 className="align-self-center m-0">Multi Choice Prediction Templates</h2>
                                    </div>
                                    <div className="heading-top-area-right">
                                        <div className="search-area">
                                            <label>Search</label>
                                            <form
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control inner-input"
                                                    placeholder="Enter Title"
                                                    onChange={(e) => handleSearchChange(e.target.value)}
                                                    value={soloSearch}
                                                    onKeyDown={(e) => {
                                                        if (!e.target.value && e.code === "Space") {
                                                            e.preventDefault();
                                                        }
                                                        if (e.key === "Enter") {
                                                            handleSearchChange(soloSearch);
                                                        }
                                                    }}
                                                />
                                                <i
                                                    className="fa fa-search"
                                                    aria-hidden="true"
                                                    onClick={() => handleSearchChange(soloSearch)}
                                                ></i>
                                                <i
                                                    className="fa fa-close d-none"
                                                    aria-hidden="true"
                                                ></i>
                                            </form>
                                        </div>

                                        <div className="filter-top-area me-2">
                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <Form.Label className="mb-0">Filter:</Form.Label>
                                                <Select
                                                    options={soloTypeFilteroptions}
                                                    placeholder="All"
                                                    onChange={(e) => handleSoloTypeFilterChange(e.value)}

                                                />
                                            </Form.Group>
                                        </div>

                                    </div>
                                </div>
                            </Col>

                        </Row>

                        <Row className="pb-4 justify-content-center">
                            <Col md={12} lg={11}>
                                <div className="availabilit-section">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Title</th>
                                                <th className="email-section">
                                                    Duration
                                                </th>
                                                {/* <th>Status</th> */}
                                                <th>Type</th>
                                                <th>Copies</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {soloPredictionsList && soloPredictionsList?.length > 0 ? (
                                                soloPredictionsList?.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td> {(page * 10) + (index + 1)}</td>
                                                            <td className="question-area">
                                                                <a>
                                                                    {data?.title ? (
                                                                        <>
                                                                            {data?.title?.length > 50 ? (
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Tooltip id="tooltip">
                                                                                            {data?.title}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <span className="hover-text">
                                                                                        {data?.title.substring(0, 50)}...
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                            ) : (
                                                                                data?.title
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>-</>
                                                                    )}
                                                                </a>
                                                            </td>
                                                            <td >
                                                                {data.duration}

                                                            </td>



                                                            {/* <td> */}
                                                            {/* <div className="view-eye-area">
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => onChangeStatus(data?._id, !data?.isDisabled)}
                                                                    >
                                                                        <div className="edit-pencil">
                                                                            <input type="checkbox" switch checked={!data?.isDisabled} />

                                                                        </div>
                                                                    </div>

                                                                </div> */}
                                                            {/* <div className="solo-template-switch">
                                                                    <Form>
                                                                        <Form.Check // prettier-ignore
                                                                            type="switch"
                                                                            id="custom-switch"
                                                                            onClick={() => onChangeStatus(data?._id, !data?.isDisabled)}
                                                                            checked={!data?.isDisabled}
                                                                        />
                                                                    </Form>
                                                                </div> */}

                                                            {/* </td> */}
                                                            <td className="capitalized">
                                                                {data?.type}
                                                            </td>
                                                            <td>
                                                                {data?.timesAdded}
                                                            </td>

                                                            <td>

                                                                <div className="view-eye-area">
                                                                    <Button type="buttton" variant="unset" className="launch-btn" onClick={() => launchPrediction(data._id)}>Launch</Button>
                                                                    <div
                                                                        className="d-flex align-items-center"
                                                                        onClick={() => {
                                                                            setUpdateSoloData(data)
                                                                            setModalVisible(true)
                                                                        }}
                                                                    >
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={() => {
                                                                                return (
                                                                                    <Tooltip id="tooltip">
                                                                                        <p className="m-0 p-0">Edit</p>
                                                                                    </Tooltip>
                                                                                )
                                                                            }}
                                                                        >
                                                                            <div className="edit-pencil">
                                                                                <i
                                                                                    className="fa fa-pencil"
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>
                                                                            </div>
                                                                        </OverlayTrigger>{" "}
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={() => {
                                                                                return (
                                                                                    <Tooltip id="tooltip">
                                                                                        <p className="m-0 p-0">View</p>
                                                                                    </Tooltip>
                                                                                )
                                                                            }}
                                                                        >
                                                                            <div className="edit-pencil">
                                                                                <i
                                                                                    class="fa fa-eye"
                                                                                    onClick={() => { history("/multichoice-prediction-template-detail/" + data?._id) }
                                                                                    }
                                                                                    style={{ cursor: "pointer" }}
                                                                                    aria-hidden="true"
                                                                                ></i>
                                                                            </div>
                                                                        </OverlayTrigger>{" "}
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                {soloPredictionsList.length > 0 && totalItems > 10 ? (
                                    <Pagination
                                        activePage={page + 1}
                                        itemsCountPerPage={10}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Modal
                className="add-pediction-area"
                show={modalVisible}
                onHide={() => onCloseModal()}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {updateSoloData?._id ? "Update Solo Templates" : "Add Solo Templates"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-3">
                    <Row md={12}>
                        <Form>
                            <Form.Group className="mb-2">
                                <Form.Label>Image</Form.Label>
                                <div className="common-image-area">
                                    {!updateSoloData.image && (
                                        <div className="upload-image-area">
                                            <input type="file"
                                                onChange={(e) => onSelectImage(e)}
                                                accept="image/*"
                                            />
                                            <i class="fa fa-plus" aria-hidden="true"></i>{" "}
                                            {updateSoloError.image &&
                                                <span className="text-danger">
                                                    {updateSoloError.image}
                                                </span>
                                            }
                                        </div>
                                    )}
                                    {updateSoloData.image && (
                                        <div className="upload-image-area-spacing">
                                            <div className="upload-image-area-right">
                                                <img
                                                    src={updateSoloData.image?.name ? URL.createObjectURL(updateSoloData.image) : baseUrl + updateSoloData.image}
                                                    alt="iamge"
                                                    height={200}
                                                    width={200}
                                                />
                                                <i
                                                    class="fa fa-trash"
                                                    style={{ cursor: "pointer" }}
                                                    aria-hidden="true"
                                                    onClick={() => setUpdateSoloData((prevState) => ({ ...prevState, image: "" }))}
                                                ></i>

                                            </div>

                                            <div className="choose-image-area">
                                                <button
                                                    type="button"
                                                    style={{ cursor: "pointer" }}
                                                    variant="unset"
                                                >
                                                    Change Image
                                                </button>
                                                <input type="file" onChange={(e) => onSelectImage(e)} accept="image/*" />
                                            </div>


                                        </div>
                                    )}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    Title <span>*</span>
                                </Form.Label>
                                <input
                                    maxLength={100}
                                    className="form-control "
                                    type="textarea"
                                    value={updateSoloData.title}
                                    name="title"
                                    onChange={onChangeText}
                                />
                                {updateSoloError.title &&
                                    <span className="text-danger">
                                        {updateSoloError.title}
                                    </span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>
                                    Description <span>*</span>
                                </Form.Label>
                                <textarea
                                    maxLength={1500}
                                    className="form-control "
                                    type="textarea"
                                    name="description"
                                    value={updateSoloData.description}
                                    onChange={onChangeText}
                                />
                                {updateSoloError.description &&
                                    <span className="text-danger">
                                        {updateSoloError.description}
                                    </span>
                                }
                            </Form.Group>
                            {updateSoloData.options.map((item, index) => (
                                <Form.Group className="mb-2 option-area" key={index}>
                                    <Form.Label>Option {index + 1} <span>*</span></Form.Label>
                                    <div className="third-option">
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={item}
                                            onChange={(e) => updateOption(index, e.target.value)}
                                        />
                                        {updateSoloData.options.length > 2 && (
                                            <i
                                                className="fa fa-trash"
                                                style={{ cursor: "pointer" }}
                                                aria-hidden="true"
                                                onClick={() => removeOption(index)}
                                            ></i>
                                        )}
                                    </div>
                                    {updateSoloError.options[index] && (
                                        <span className="text-danger">{updateSoloError.options[index]}</span>
                                    )}
                                </Form.Group>
                            ))}
                            {/* {updateSoloData.options.length < 7 && ( */}
                            <p className="add-option">
                                <i className="fa fa-plus" aria-hidden="true" onClick={addOption}></i>
                            </p>
                            {/* )} */}
                        </Form>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => onSave()}>Save</Button>
                    <Button onClick={() => onCloseModal()} className="close-btn-bottom">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
