import {
  Col,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
  baseUrl,
  dateFormate,
  formatBalance,
  formatWithDecimals,
  transactionurl,

} from "../../config/config";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import copy from "copy-to-clipboard";
import { apiService } from "../../service/api.service";
import { CopyToClipboard } from "react-copy-to-clipboard";
export const TransactionManagement = () => {
  let history = useNavigate();
  const [page, setPage] = useState(0);
  const [transactions, settransactions] = useState();
  const [totalItems, setTotalItems] = useState();
  const [walletaddress, setwalletaddresss] = useState("");
  const [contract_address, setcontract_address] = useState("");
  const [predictionDetail, setPredictionDetail] = useState([]);
  const [copied, setcopied] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [resultPrediction, setresultPrediction] = useState("");
  const formatAddress = (addr) => {
    return `${addr.substring(0, 4)}...${addr.substring(addr.length - 4)}`;
  };
  const [loader, setLoader] = useState(false);
  const props = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const wallet_address = searchParams.get("wallet_address");
  useEffect(() => {
    if (searchParams.get("wallet_address")) {
      setwalletaddresss(searchParams.get("wallet_address"));
      transaction_list(
        page,
        contract_address,
        searchParams.get("wallet_address"),
        false
      );
    } else if (searchParams.get("contract")) {
      setFiltered(true);
      setcontract_address(searchParams.get("contract"));
      transaction_list(page, searchParams.get("contract"), walletaddress, true);
    } else {
      transaction_list(page, contract_address, walletaddress, false);
    }
  }, []);

  async function transaction_list(
    page,
    address,
    walletaddress,
    is_filterby_prediction
  ) {
    setLoader(true);
    try {
      const response = await apiService.transaction_list(
        page,
        address,
        walletaddress
      );
      if (response?.status == 200) {
        console.log("response.data.data", response.data.data);
        let responseData = response.data.data.predictionDetail;
        console.log("responseData============", responseData)
        if (is_filterby_prediction) {
          setresultPrediction(responseData[0].result?.toString());
          setPredictionDetail(responseData);
        } else {
          settransactions(responseData);
        }

        setTotalItems(response?.data?.data.totalRecords);

        setLoader(false);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false);

        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        setLoader(false);

        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  }
  function PredictionDetail(id) {
    history("/prediction-detail/" + id);
  }
  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber);
    if (page != pageNumber - 1) {
      setPage(pageNumber - 1);
      transaction_list(
        pageNumber - 1,
        contract_address,
        walletaddress,
        filtered
      );
    }
  }

  const tooltip = <Tooltip id="tooltip">View</Tooltip>;
  const setcopytext = () => {
    setTimeout(() => {
      setcopied(false);
    }, "1000");
  };
  const copytooltip = (
    <Tooltip id="tooltip">{copied ? "Copied" : "Copy"}</Tooltip>
  );
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">
                      Transaction Management
                    </h2>
                  </div>
                  {resultPrediction &&
                    resultPrediction.length > 0 &&
                    resultPrediction[0] && (
                      <h5>
                        Result :
                        {resultPrediction[0].toUpperCase() +
                          resultPrediction.slice(1)}
                      </h5>
                    )}
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Wallet Address</th>
                        <th>Prediction Participation</th>
                        <th>Amount Spent</th>
                        <th>Predicted</th>
                        <th>Prediction Type</th>
                        <th>Result</th>
                        <th>Date</th>
                        <th>Reward </th>
                        <th>View Transaction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {totalItems != null &&
                        totalItems > 0 &&
                        transactions &&
                        transactions?.length > 0 ? (
                        transactions.map((data, index) => {
                          console.log("data>>>>>>", data);
                          let priductedValue = data.prediction.toString();
                          let answer = "N/A";
                          if (
                            data.prediction_id &&
                            data.prediction_id.result != null
                          ) {
                            answer = data.prediction_id.result.toString();
                          }

                          return (
                            <tr>
                              <td>{index + 1}</td>

                              <td onClick={setcopytext}>
                                {formatAddress(data.user)}{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={copytooltip}
                                >
                                  <CopyToClipboard
                                    text={data.user}
                                    onCopy={() => setcopied(true)}
                                  >
                                    <i
                                      class="fa fa-clipboard"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                    ></i>
                                  </CopyToClipboard>
                                </OverlayTrigger>
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  PredictionDetail(
                                    data.prediction_id.contract_address.toLowerCase()
                                  )
                                }
                              >

                                {data?.prediction_id?.question?.length > 50 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {data?.prediction_id?.question}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="hover-text">
                                      {data?.prediction_id?.question.substring(0, 50)}...
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  data?.prediction_id?.question
                                )}
                              </td>

                              <td>{data?.amount ? formatWithDecimals(data?.amount, data?.prediction_id?.decimal) : "N/A"}</td>
                              <td>
                                {priductedValue[0].toUpperCase() +
                                  priductedValue.slice(1)}
                              </td>
                              <td>
                                {data?.prediction_id?.prediction_type ? data?.prediction_id?.prediction_type === "RISK-FREE" ? "Prize pool" : data?.prediction_id?.prediction_type : "N/A"}
                                {data?.prediction_id?.prediction_category !== "REGULAR" && (
                                  <>
                                    {data?.prediction_id?.prediction_category === "POLL" && " ( POLL )"}
                                    {data?.prediction_id?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                    <span className="capitalized">
                                      &nbsp; {data?.prediction_id?.solo_type}
                                    </span>

                                  </>
                                )}
                              </td>
                              <td>
                                {answer[0].toUpperCase() + answer.slice(1)}
                              </td>

                              <td>{data.date ? moment(data.date).format(dateFormate) : "N/A"}</td>
                              <td> {data?.reward_amount ? formatWithDecimals(data?.reward_amount, data?.prediction_id?.decimal) : "N/A"}</td>
                              <td>
                                <a
                                  href={transactionurl + data.transaction_hash}
                                  target="_blank"
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip}
                                  >
                                    <i
                                      class="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                    ></i>
                                  </OverlayTrigger>
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : totalItems != null &&
                        totalItems > 0 &&
                        predictionDetail &&
                        predictionDetail.length > 0 ? (
                        predictionDetail[0].prediction_id.map((data, index) => {
                          console.log("predictionDetail===================", predictionDetail)
                          let priductedValue = data.prediction.toString();
                          let answer = "N/A";
                          if (predictionDetail[0].result != null) {
                            answer = predictionDetail[0].result.toString();
                          }

                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{formatAddress(data.user)}</td>
                              <td>
                                {predictionDetail[0].question.length > 50
                                  ? predictionDetail[0].question.slice(0, 50) +
                                  "..."
                                  : predictionDetail[0].question}
                              </td>
                              <td>{formatWithDecimals(data?.amount, data?.prediction_id?.decimal)} {data?.prediction_id?.symbol}</td>

                              <td>
                                {priductedValue[0].toUpperCase() +
                                  priductedValue.slice(1)}
                              </td>
                              <td>
                                {predictionDetail[0]?.prediction_type ? predictionDetail[0]?.prediction_type === "RISK-FREE" ? "Prize pool" : predictionDetail[0]?.prediction_type : "N/A"}
                                {predictionDetail[0]?.prediction_category !== "REGULAR" && (
                                  <>
                                    {predictionDetail[0]?.prediction_category === "POLL" && " ( POLL )"}
                                    {predictionDetail[0]?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                  </>
                                )}
                              </td>
                              <td>
                                {answer[0].toUpperCase() + answer.slice(1)}
                              </td>
                              <td>{moment(data.date).format(dateFormate)}</td>
                              <td> {formatWithDecimals(data?.reward_amount, predictionDetail[0]?.decimal)} {predictionDetail[0]?.symbol}</td>
                              <td>
                                <a
                                  href={transactionurl + data.transaction_hash}
                                  target="_blank"
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={tooltip}
                                  >
                                    <i
                                      class="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                      aria-hidden="true"
                                    ></i>
                                  </OverlayTrigger>
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  {totalItems && totalItems > 0 ? (
                    <Pagination
                      activePage={page + 1}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};
